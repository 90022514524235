import { React, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Flex,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import routes from "../../routes";
import Navbar from "../../components/Navbar/Navbar";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import nftproject from "../../assets/projects/rollingloud.jpg";
import medhub from "../../assets/projects/reggae.jpg";
import collab from "../../assets/projects/basscanyon.jpg";
import blog from "../../assets/projects/eprincipal.png";
import eeg from "../../assets/projects/everwild.jpg";
import mtrans from "../../assets/projects/findiss.png";
import steg from "../../assets/projects/lostlands.jpg";
import {CONSTDATA} from '../../localData/CONSTDATA';
import images from "../../helps";

export default function Projects() {
  const textColorPrimary = useColorModeValue(
    "secondaryGray.900",
    "lightpeach.100"
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "20px", md: "40px", xl: "40px" }}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        ></Flex>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          gap="25px"
          alignItems="center"
          justifyContent="center"
        >
                                  {
                          CONSTDATA.projects.map((project, index) => {
                            return (
                              <ProjectCard
                              projectImage={images[project.logo]}
                              projectName={project.name}
                              aboutProject={project.technology}
                              techStack={"EXPRESS JS, NODE JS, REACT JS, MONGODB, SOLIDITY"}
                              androidLiveLink={project.android}
                              iosLiveLink={project.ios}
                            />
                            )
                          })
                        }
        </SimpleGrid>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="Center"
          mt="40px"
          mb="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<BLOGS/>"}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          gap="50px"
          alignItems="center"
          justifyContent="center"
        >
          <ProjectCard
            projectImage={steg}
            projectName={"Steganography Blog Part 1"}
            aboutProject={"This blog is about hiding text inside an Image"}
            projectLink="https://rahul1582.medium.com/steganography-6c9af950cbb3"
          />
          <ProjectCard
            projectImage={steg}
            projectName={"Steganography Blog Part 2"}
            aboutProject={"This Blog is about hiding an Image inside an Image"}
            projectLink="https://rahul1582.medium.com/steganography-part-2-ad7452a3ff1"
          />
        </SimpleGrid>
      </Box>
    </Box>
  );
}
